// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.visibilityCheck {
//.completeVisibilityCheck {
    & > div:nth-child(1) {
        transform: translateX(-150%);
        transition: all .5s ease-out;
    }
    & > div:nth-child(2) {
        transform: translateX(150%);
        transition: all .5s ease-out;
    }
    &.isVisible {
    //&.isCompletelyVisible{
        & > div:nth-child(1), & > div:nth-child(2) {
            transform: translateX(0);
            transition: all .5s ease-out;
        }
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';
@import 'sections/gallery-products';
/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*****************************************************/
/* INDEX.PHP
/*****************************************************/
section.section-white-text {
    padding: 80px 0;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;
        width: 90%;
        max-width: 1500px;
        & > div{
            width: 44%;
            padding: 0 15px;
        }
    }

    div.left-side {
        border-left: 10px solid $color-1;
    }
    div.right-side {
        border-right: 10px solid $color-1;
        .text-logo {
            display: flex;
            padding-top: 25px;
            .right {
                display: flex;
            }
        }
    }

    div.logos {
        display: flex;
        flex-flow: wrap;
        align-items: flex-start;
        justify-content: space-between;
        & > div {
            width: 30%;
            transition: .3s;
            @media screen and (max-width: 500px){
                width: 50%;
                transition: .3s;
            }
        }

    }

    h4 {
        font-weight: 600;
        padding-bottom: 20px;
        line-height: 1;
        font-size: $font-size-30;
    }
    li {
        font-weight: 600;
        font-size: $font-size-26;
    }
    p, li {
        line-height: 1.3;
    }

    //mobile
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row, .container-fluid.row.texteVert {
            & > div {
                width: 100%;
                transition: .3s;
                padding: 0 15px;
            }
        }
        div.left-side {
            border-right: 10px solid $color-1;
        }
        div.right-side {
            border-left: 10px solid $color-1;
        }
    }
}

section.section-black {
    background-color: $color-black;
    padding: 20px 0;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;
        width: 90%;
        max-width: 1500px;

        & > div {
            width: 44%;
            padding: 0 15px;
        }
    }

    h3 {
        line-height: 1;
        font-weight: 400;
        color: $color-white;
        padding-bottom: 20px;
        font-size: $font-size-40;
        text-transform: uppercase;
    }
    p {
        line-height: 1.3;
        font-weight: 400;
        color: $color-white;
        font-size: $font-size-24;
    }


    @media screen and (max-width: $size-xs-max) {
        div.container-fluid.row {
            & > div {
                width: 100%;
                transition: .3s;
                padding: 0 15px;
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

.container-fluid.row.texteVert {
    padding-top: 75px;
    h4 {
        font-weight: 600;
        padding-bottom: 25px;
        color: #009a52;
        font-size: $font-size-26;
    }
}

section.section-black_propos {
    background-color: #8e9c9d;
    padding: 20px 0;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;
        width: 90%;
        max-width: 1500px;
        .text-box {
            width: 35%;
        }
        .double-img-side {
            width: 60%;
        }
        & > div {
            width: 50%;
            padding: 0 15px;
        }
    }

    h3 {
        line-height: 1;
        font-weight: 400;
        color: $color-white;
        padding-bottom: 20px;
        font-size: $font-size-40;
        text-transform: uppercase;
    }
    p {
        line-height: 1.3;
        font-weight: 400;
        color: $color-white;
        font-size: $font-size-24;
    }
    li {
        line-height: 1.3;
        font-weight: 400;
        color: $color-white;
        font-size: $font-size-30;
    }


    @media screen and (max-width: $size-xs-max) {
        div.container-fluid.row {
            & > div {
                width: 100%;
                transition: .3s;
                padding: 0 15px;
            }
        }
    }
}

section.section-logo {
    padding: 80px 0;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;
        width: 90%;
        max-width: 1500px;
        & > div{
            width: 93%;
            padding: 0 15px;
        }
    }

    div.logos {
        display: flex;
        flex-flow: wrap;
        align-items: flex-start;
        justify-content: space-between;
        & > div {
            width: 15%;
            padding: 15px;
            transition: .3s;
            @media screen and (max-width: 500px){
                width: 50%;
                transition: .3s;
            }
        }

    }

    h4 {
        font-weight: 600;
        padding-bottom: 25px;
        line-height: 1.3;
        font-size: $font-size-26;
    }
    p, li {
        line-height: 1.3;
    }

    //mobile
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
            & > div {
                width: 100%;
                transition: .3s;
                padding: 0 15px;
            }
        }
        div.left-side {
            border-right: 10px solid $color-1;
        }
        div.right-side {
            border-left: 10px solid $color-1;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

section.section-white-text_services {
    padding: 80px 0 100px 0;
    position: relative;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;
        width: 90%;
        max-width: 1500px;
        & > div{
            width: 44%;
            padding: 0 15px;
            .text-logo {
                display: flex;
                padding-top: 25px;
                .right {
                    display: flex;
                }
            }
        }
    }
    .blocService {
        background: #8e9c9d;
        width: 50%;
        padding: 30px 2% 55px 15%;
        position: absolute;
        left: 0;
        top: 40%;
        h3 {
            color: #fff;
            font-weight: 500;
            font-size: $font-size-50;
            text-transform: uppercase;
        }
        p {
            color: #fff;
            font-weight: 500;
            font-size: $font-size-36;
        }
    }
    div.left-side {
        border-left: 10px solid $color-1;
        height: 180px;
    }
    div.right-side {
        border-right: 10px solid $color-1;
    }

    div.logos {
        display: flex;
        flex-flow: wrap;
        align-items: flex-start;
        justify-content: space-between;
        & > div {
            width: 30%;
            transition: .3s;
            @media screen and (max-width: 500px){
                width: 50%;
                transition: .3s;
            }
        }

    }

    h4 {
        font-weight: 600;
        padding-bottom: 20px;
        line-height: 1;
        font-size: $font-size-30;
    }
    li {
        font-weight: 600;
        font-size: $font-size-26;
    }
    p, li {
        line-height: 1.3;
    }

    //mobile
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row, .container-fluid.row.texteVert {
            & > div {
                width: 100%;
                transition: .3s;
                padding: 0 15px;
            }
        }
        div.left-side {
            border-right: 10px solid $color-1;
        }
        div.right-side {
            border-left: 10px solid $color-1;
        }
    }
}

section.section-black_services {
    background-color: #000;
    padding: 20px 0 30px 0;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;
        width: 90%;
        max-width: 1500px;
        .blocListe {
            display: flex;
            .left-side {
                width: 78%;
                margin-right: 25px;
            }
        }
        .text-box {
            width: 60%;
        }
        & > div {
            width: 30%;
            padding: 0 15px;
        }
    }

    h3 {
        line-height: 1;
        font-weight: 400;
        color: $color-white;
        padding-bottom: 20px;
        font-size: $font-size-40;
        text-transform: uppercase;
    }
    li {
        line-height: 1.3;
        font-weight: 400;
        color: $color-white;
        font-size: $font-size-26;
    }


    @media screen and (max-width: $size-xs-max) {
        div.container-fluid.row {
            & > div {
                width: 100%;
                transition: .3s;
                padding: 0 15px;
            }
        }
    }
}

section.section-texte-image {
    padding: 100px 0;

    div.container-fluid.row {
        margin: auto;
        width: 90%;
        max-width: 1500px;
        .texte-images {
            display: flex;
            .left-side {
                border-left: 10px solid #019b52;
                width: 50%;
                .texte {
                    padding-left: 15px;
                    p {
                        line-height: 1.3;
                        font-weight: 600;
                        color: $color-black;
                        font-size: $font-size-30;
                    }
                    span {
                        color: #009950;
                    }
                }
                .texte.middle {
                    padding: 20px 0 20px 15px;
                }
            }
            .right-side {
                display: flex;
                width: 50%;
            }
        }
    }
}

/*******************************************************************************
* NOS ÉQUIPEMENTS
*******************************************************************************/

#blocIntro {
    padding: 10px 25px 50px 25px;
    border-left: 10px solid #009a50;
    margin: 75px 5%;
    h3 {
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-30;
        padding-bottom: 20px;
    }
    p {
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-24;
        margin: 0;
        line-height: 1.3;
    }
    p.bottom {
        padding-top: 30px;
    }
}

#blocEquipement {
    padding: 0 5% 50px 5%;
    h3 {
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-30;
        padding-bottom: 35px;
    }
    .rangee {
        padding-bottom: 75px;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        .bloc.tour {
            .texte {
                width: 270px;
            }
        }
        .bloc.liste {
            width: 100%;
            .equipement.left {
                width: 50%;
            }
            ul {
                li {
                    list-style: none;
                    font-weight: 600;
                    font-size: $font-size-24;
                    line-height: 1.5;
                    padding-bottom: 5px;
                    display: flex;
                    span {
                        padding-right: 15px;
                    }
                }
            }
        }
        .bloc {
            h3 {
                font-weight: 600;
                color:#009a52;
                font-size: $font-size-24;
            }
            h3.vertical {
                text-align: center;
            }
            .contenu {
                display: flex;
                .equipement {
                    padding-right: 30px;
                }
                .equipement.second {
                    padding-right: 200px;
                }
                .section-text {
                    display: flex;
                }
            }
            .texte {
                background: #000;
                text-align: center;
                min-height: 125px;
                width: 320px;
                margin: auto;
                padding: 10px 5px;
                margin-top: 25px;
                h3 {
                    font-weight: 600;
                    color: $color-white;
                    font-size: $font-size-30;
                    padding-bottom: 0;
                }
                p {
                    font-weight: 600;
                    color: $color-white;
                    font-size: $font-size-24;
                    margin: 0;
                    line-height: 1.3;
                }
            }
            .image.middle {
                border-right: 10px solid #009950;
                border-left: 10px solid #009950;
                padding: 0 50px;
            }
        }
    }
}

#blocLogo {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 5% 75px 5%;
    .logo {
        width: 15%;
        margin: auto;
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#blocDetails {
    display: flex;
    padding: 0 5%;
    .left-side {
        width: 50%;
    }
    h3 {
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-30;
        padding-bottom: 45px;
    }
    ul {
        padding-left: 30px;
        li {
            font-weight: 600;
            color: $color-black;
            font-size: $font-size-30;
            padding-bottom: 10px;
        }
    }
    p {
        font-weight: 600;
        color: #009a52;
        font-size: $font-size-30;
        margin: 0;
        line-height: 1.3;
    }
}

#blocEmploi {
    padding: 50px 5%;
    background: #000;
    h3 {
        font-weight: 600;
        color: $color-white;
        font-size: $font-size-30;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    .emploi {
        display: flex;
        .bloc {
            width: 43%;
            margin-right: 7%;
            .border {
                border-left: 10px solid #009a52;
                padding-left: 15px;
            }
            h3 {
                font-weight: 600;
                color: $color-white;
                font-size: $font-size-30;
                padding-bottom: 0;
                text-transform: initial;
            }
            p {
                font-weight: 600;
                color: $color-white;
                font-size: $font-size-24;
                margin: 0;
                line-height: 1.3;
            }
            .bouton {
                padding-top: 100px;
                padding-left: 3%;
                a.btnPostulez {
                    background: #8e9c9d;
                    padding: 20px 140px;
                    font-weight: 600;
                    color: $color-white;
                    font-size: $font-size-30;
                }
            }
        }
    }
}

#blocFormulaire {
    padding: 50px 5% 0 5%;
    overflow: hidden;
    h3 {
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-30;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    .titre {
        border-left: 10px solid #009a52;
        padding-left: 15px;
        height: 200px;
    }
    .section-form {
        position: relative;
        left: 3%;
        bottom: 130px;
    }
}

#blocLogo_emploi {
    padding: 0 5% 50px 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .image {
        width: 18%;
        padding: 25px;
        img.img-responsive {
            margin: auto;
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/









/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocIntro_coordonnees {
    display: flex;
    align-items: center;
    padding: 100px 0 100px 5%;
    .left-side {
        width: 50%;
        border-left: 10px solid #000;
        padding: 20px 0;
        p {
            line-height: 1.3;
            font-weight: 600;
            color: $color-black;
            font-size: $font-size-30;
            margin: 0;
            padding-left: 20px;
        }
        p.adresse {
            padding: 50px 20px;
        }
        .telFax-num {
            display: flex;
        }
    }
    .right-side {
        width: 50%;
        border-left: 10px solid #000;
    }
}

#blocTitre {
    padding: 0 5%;
    h4 {
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-30;
        padding-bottom: 40px;
    }
}

#blocEquipe {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5% 75px 5%;
        .bloc {
        width: 33.3333%;
        border-left: 10px solid #009a50;
        padding: 5px 25px;
        margin-bottom: 35px;
        height: 245px;
        p {
            line-height: 1.3;
            font-weight: 600;
            color: $color-black;
            font-size: $font-size-30;
            margin: 0;
            &:first-child {
                padding-top: 13%;
            }
            a:hover {
                color: $color-black;
            }
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1735px) {

#blocEquipe .bloc p:first-child {
    padding-top: 15%;
}
}

@media screen and (max-width: 1635px) {

section.section-white-text .container-fluid.row.texteVert {
    width: 80% !important;
    text-align: center;
}
}

@media screen and (max-width: 1610px) {

section.section-white-text_services .blocService h3 {
    font-size: 2.2rem;
}
#blocEquipement .rangee {
    flex-wrap: wrap;
}
#blocEquipement .rangee .bloc .image.middle {
    border-right: none;
    border-left: none;
    padding: 0;
}
#blocEquipement .rangee .bloc.bloc3 {
    padding-top: 50px;
}
form input.form-control, form textarea.form-control {
    width: 50%;
}
form textarea.form-control {
    width: 70%;
}
}

@media screen and (max-width: 1550px) {

section.section-black_services div.container-fluid.row .blocListe .left-side {
    width: 100%;
}
section.section-texte-image div.container-fluid.row .texte-images .left-side {
    width: 60%;
}
#blocEquipe .bloc p:first-child {
    padding-top: 17%;
}
}

@media screen and (max-width: 1465px) {

section.section-white-text div.right-side .text-logo .left, section.section-white-text_services div.container-fluid.row > div .text-logo .left {
    width: 30%;
}
}

@media screen and (max-width: 1430px) {

section.section-black_services div.container-fluid.row .text-box {
    width: 65%;
}
#blocEquipe .bloc p:first-child {
    padding-top: 18%;
}
}

@media screen and (max-width: 1415px) {

section.section-white-text_services .blocService h3 {
    font-size: 2rem;
}
section.section-white-text_services .blocService {
    padding: 30px 2% 55px 11%;
}
section.section-texte-image div.container-fluid.row .texte-images .left-side .texte p {
    font-size: 1.3rem;
}
section.section-texte-image div.container-fluid.row .texte-images .left-side {
    width: 45%;
    margin-right: 5%;
}
#blocEquipement .rangee .bloc .contenu .equipement.second {
    padding-right: 60px;
}
}

@media screen and (max-width: 1395px) {

#blocEquipe .bloc p {
    font-size: 1.3rem;
}
}

@media screen and (max-width: 1335px) {

section.section-black_services li {
    font-size: 1.2rem;
}
#blocDetails .left-side {
    width: 60%;
}
form label {
    width: 25%;
}
form textarea.form-control {
    width: 75%;
}
}

@media screen and (max-width: $size-md-max) {

section.section-logo div.logos > div {
    width: 21%;
}
section.section-white-text_services .blocService {
    padding: 30px 2% 55px 11%;
}
section.section-black_services div.container-fluid.row {
    width: 95%;
}
#blocIntro_coordonnees .left-side p, #blocEquipe .bloc p {
    font-size: 1.4rem;
}
#blocEquipe .bloc p:first-child {
    padding-top: 20%;
}
#blocLogo {
    padding: 0px 2% 75px 2%;
}
#blocEquipement .rangee .bloc .contenu .equipement.second {
    padding-right: 0;
}
}

@media screen and (max-width: 1220px) {

#blocEquipement .rangee {
    flex-direction: column;
    align-items: initial;
}
#blocEquipement .rangee .bloc h3.vertical {
    text-align: left;
    padding-top: 35px;
}
#blocEquipement .rangee .bloc .texte {
    margin: initial;
}
#blocIntro_coordonnees .left-side p, #blocEquipe .bloc p {
    font-size: 1.3rem;
}
}

@media screen and (max-width: 1024px) {

section.section-white-text .container-fluid.row.texteVert {
    width: 77% !important;
}
.module-header--caption {
    top: 87%;
    right: 10%;
}
section.section-white-text_services .blocService h3 {
    font-size: 1.8rem;
}
section.section-white-text_services .blocService p {
    font-size: 1.6rem;
}
section.section-black_services li {
    font-size: 1.1rem;
}
section.section-black_services div.container-fluid.row .text-box {
    width: 67%;
}
#blocEquipe .bloc {
    width: 50%;
    height: 215px;
}
#blocEquipe .bloc p:first-child {
    padding-top: 16%;
}
#blocEquipement {
    padding: 0 3% 50px 3%;
}
#blocEquipement .rangee .bloc .contenu .equipement {
    padding-right: 10px;
    padding-top: 15px;
}
form label {
    width: 30%;
}
form input.form-control, form textarea.form-control {
    width: 60%;
}
form textarea.form-control {
    width: 90%;
}
#blocDetails .left-side {
    width: 80%;
}
#blocLogo_emploi .image {
    width: 25%;
    padding: 25px;
}
section.section-white-text div.right-side .text-logo .left, section.section-white-text_services div.container-fluid.row > div .text-logo .left {
    width: 60%;
}
section.section-white-text div.right-side .text-logo, section.section-white-text_services div.container-fluid.row > div .text-logo {
    align-items: center;
}
section.section-black_propos div.container-fluid.row .double-img-side {
    width: 55%;
}
section.section-black_propos div.container-fluid.row .text-box {
    width: 40%;
}
#blocEquipement .rangee .bloc .texte.okuma {
    width: 345px !important;
}
}

@media screen and (max-width: $size-sm-max) {

section.section-logo div.logos > div {
    width: 35%;
}
section.section-white-text_services .blocService {
    padding: 30px 2% 55px 5%;
}
section.section-texte-image div.container-fluid.row .texte-images {
    flex-direction: column;
}
section.section-texte-image div.container-fluid.row .texte-images .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
}
section.section-texte-image div.container-fluid.row .texte-images .right-side {
    display: flex;
    width: 100%;
}
section.section-black_services div.container-fluid.row .blocListe {
    flex-direction: column;
}
section.section-black_services div.container-fluid.row {
    width: 100%;
    flex-direction: column;
    display: block;
}
section.section-black_services div.container-fluid.row .left-side ul {
    margin: 0;
}
section.section-black_services div.container-fluid.row > div {
    width: 100%;
    padding: 15px;
}
#blocIntro_coordonnees {
    align-items: initial;
    padding: 50px 5% 75px 5%;
    flex-direction: column;
}
#blocIntro_coordonnees .left-side {
    margin-bottom: 35px;
    width: 100%;
    border-left: 10px solid #000;
}
#blocIntro_coordonnees .right-side {
    width: 100%;
    border-left: 10px solid #000;
}
#blocEquipement .rangee .bloc .contenu {
    display: flex;
    flex-wrap: wrap;
}
#blocLogo .logo {
    width: 40%;
    padding: 25px;
}
#blocDetails {
    flex-direction: column;
}
#blocDetails .left-side {
    width: 100%;
    padding-bottom: 20px;
}
#blocLogo_emploi {
    padding: 25px 5% 50px 5%;
}
#blocLogo_emploi .image {
    width: 50%;
    padding: 25px;
}
#blocEmploi .emploi {
    flex-direction: column;
}
#blocEmploi .emploi .bloc .bouton {
    padding-top: 60px;
}
#blocEmploi .emploi .bloc {
    width: 100%;
    padding-bottom: 80px;
    margin-right: 0;
}
form label {
    width: 35%;
}
form textarea.form-control {
    width: 95%;
}
#blocFormulaire .section-form {
    position: relative;
    left: 5%;
    bottom: 130px;
}
#blocEquipe .bloc {
    height: 190px;
}
section.section-white-text div.right-side .text-logo, section.section-white-text_services div.container-fluid.row > div .text-logo {
    align-items: flex-start;
    flex-direction: column;
}
#blocEquipement .rangee .bloc.tour .texte {
    width: 241px;
}
#blocEquipement .rangee .bloc .texte.mazak {
    width: 345px !important;
}
#blocEquipement .rangee .bloc.liste .equipement.left {
    width: 100%;
}
#blocEquipement .rangee .bloc.liste .equipement.right {
    margin-top: -10px;
    padding-top: 0;
}
}

@media screen and (max-width: $size-xs-max) {

section.section-logo div.logos > div {
    width: 50%;
}
section.section-white-text .container-fluid.row.texteVert {
    width: 100% !important;
    padding-top: 50px;
}
.module-header--caption {
    top: 90%;
    right: 5%;
}
section.section-white-text_services {
    padding: 80px 0 225px 0;
}
section.section-white-text_services .blocService {
    padding: 40px 3%;
    width: 100%;
    position: absolute;
    top: 80%;
}
section.section-white-text_services div.container-fluid.row {
    width: 100%;
}
section.section-white-text_services .blocService h3 {
    font-size: 1.7rem;
}
section.section-white-text_services .blocService p {
    font-size: 1.5rem;
}
section.section-black_services div.container-fluid.row .text-box {
    width: 100%;
}
section.section-texte-image div.container-fluid.row .texte-images .right-side {
    flex-direction: column;
}
section.section-black_services li {
    font-size: 1.3rem;
}
#blocEquipe .bloc {
    width: 100%;
    height: 200px;
}
#blocEquipe .bloc p:first-child {
    padding-top: 20%;
}
#blocEquipe {
    padding: 0 5% 50px 5%;
}
#blocEquipement .rangee .bloc .contenu {
    justify-content: center;
}
#blocEquipement .rangee .bloc .contenu .equipement {
    padding-right: 0;
    padding-bottom: 25px;
}
#blocEquipement .rangee .bloc {
    margin: auto;
}
#blocLogo .logo {
    width: 50%;
    padding: 25px;
}
#blocLogo {
    padding: 0px 2% 15px 2%;
}
#blocEquipement .rangee .bloc h3, #blocEquipement .rangee .bloc h3.vertical {
    text-align: center;
}
#blocEquipement .rangee {
    padding-bottom: 50px;
}
#blocEquipement .rangee .bloc .texte {
    margin: auto;
}
#blocIntro {
    margin: 40px 5%;
    padding: 10px 25px 35px 25px;
}
#blocEmploi .emploi .bloc .bouton a.btnPostulez {
    background: #8e9c9d;
    padding: 20px 80px;
    font-weight: 600;
    color: #fff;
    font-size: 1.5rem;
}
#blocDetails h3 {
    padding-bottom: 25px;
}
#blocFormulaire .section-form .module-formulaire .form-group .flex {
    flex-direction: column;
}
form label {
    width: 100%;
}
#blocFormulaire .section-form {
    position: relative;
    left: 10%;
    bottom: 130px;
}
form input.form-control, form textarea.form-control, form textarea.form-control {
    width: 80%;
}
.btn-submit {
    min-width: 295px !important;
}
#blocIntro_coordonnees .left-side p, #blocEquipe .bloc p {
    font-size: 1.5rem;
}
section.section-black_propos div.container-fluid.row {
    flex-direction: column;
}
section.section-black_propos div.container-fluid.row .text-box, section.section-black_propos div.container-fluid.row .double-img-side {
    width: 100%;
}
#blocEquipement .rangee .bloc .contenu .section-text {
    flex-direction: column;
    padding-top: 10px;
}
#blocEquipement .rangee .bloc.tour .texte {
    width: 345px;
}
#blocEquipement .rangee .bloc.liste .equipement.right {
    margin-top: -35px;
}
}

@media screen and (max-width: 360px) {

.btn-submit {
    min-width: 255px !important;
}
}
