.slideshow-wrap {
    position: relative;
    background-color: black;
    margin-top: 125px;
    @media screen and (max-width: $size-nav-shift-breakpoint) {
        margin-top: 75px;
    }
    .ls-bar-timer {
        background: $color-1;
        border-bottom: 1px solid $color-1;
    }
    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }

    .title {
        @extend .font-1-normal;
        font-weight: 600;
        color: $color-black;
        font-size: $font-size-40;
        line-height: 1.2;
        b {
            font-weight: 800;
        }
        span {
            text-transform: uppercase;
        }
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        bottom: 50px;
        right: 10%;
        z-index: $z-index-slide-caption;
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
           font-size: 26px;
           text-align: center;
           padding: 0 10px;
        }
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: 50%;
            bottom: 50px;
            right: 0;
            left: 0;
        }

    }
}
