section.section-menu-filter {
    background-color: $color-white;
    padding: 30px 15px;

    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    ul.menu-filter {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          li {
              font-size: $font-size-28;
              padding: 0 20px;
              margin: 15px 0;
              line-height: 1;
              font-weight: bold;
              text-transform: uppercase;
              border-right: 2px solid $color-1;
              &:last-child{
                  border-right: none;
              }
              button {
                  color: $color-1;
                  text-transform: uppercase;
                  font-weight: 400;
                  background-color: #fff;
                  border-bottom-color: #fff;
                  border-top-color: #fff;
                  border-left-color: #fff;
                  border-right-color: #fff;
                  border-bottom-style: none;
                  border-top-style: none;
                  border-left-style: none;
                  border-right-style: none;
                  &:hover, &:focus, &.active {
                      color: $color-black;
                      transition: .4s;
                  }
              }
          }
    }
}

section.gallerie-products {
    padding: 20px 15px;

    div.container {
        margin: auto;
        padding: 0;
    }

    div.grid-item {
        //width: 32%;
        div.img-wrapper {
          position: relative;
          transition: .5s;
          img {
              width: 100%;
          }
        }
        margin-bottom: 30px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3n + 4),
        &:nth-child(3n + 5){
          border-right: 7px solid $color-1;
        }

        &:last-child(){
            border-right: none;
        }

        div.overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            transition: .5s;
            background: rgba(1, 155, 82, .52);
        }
        &:hover {
            div.overlay {
              opacity: 1;
              visibility: visible;
              transition: .5s;
            }
        }
    }
    @media screen and (max-width: $size-md-max){
        div.grid-item {
            width: 50%;


            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3n + 4),
            &:nth-child(3n + 5){
              border-right: none;
            }

            &:nth-child(2n+1){
              border-right: 7px solid $color-1;
            }

        }
        @media screen and (max-width: $size-slider-breakpoint){
            div.grid-item {
                width: 100%;
                flex-direction: column;
                &:nth-child(2n+1){
                  border-right: none;
                }
                div.image-side, div.text-side {
                    width: 100%;
                }
            }
        }
    }
}
